import * as React from 'react';
import {Link} from 'gatsby';

import {Form, Button} from 'react-bootstrap';

const ContactForm = () => {
  const postmanPatUrl = 'https://postmanpat.enlighten-webservices.ovh';

  const formData = React.useRef({
    name: null,
    email: null,
    phone: null,
    message: null,
  });

  const [validated, setValidated] = React.useState(false);
  const [hiddenForm, setHiddenForm] = React.useState(false);

  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const newlineToBrTag = str => str.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const handleDataChange = (event, field) => {
    formData.current[field] = event.target.value;
  };

  const sendMessage = () => {
    const messageObj = {
      template: 'manual',
      to: 'kontakt@atakama.studio',
      subject: 'Nowa wiadomość z formularza kontaktowego',
      fromName: '[Atakama Studio]',
      replyTo: formData.current.email,
      html: newlineToBrTag(`
        Nowa wiadomość z formularza kontaktowego.

        <b>Imię:</b> ${formData.current.name}
        <b>Adres email:</b> ${formData.current.email}
        <b>Telefon:</b> ${formData.current.phone}
        
        <b>Treść wiadomości:</b>

        <p>${formData.current.message}</p>
      `),
    };

    fetch(`${postmanPatUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageObj),
    })
      .then(response => {
        console.log(response);
        if (response.status !== 200) setError(true);
        else setSuccess(true);
      })
      .catch(error => {
        console.error(error);
        setError(true);
      });
  };

  const handleSubmit = event => {
    console.log('submit');
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === true) {
      setHiddenForm(true);
      sendMessage();
    }
  };

  return (
    <>
      {!hiddenForm && (
        <div className="contact-page__contact-form">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Twoje imię</Form.Label>
              <Form.Control
                type="text"
                onChange={event => handleDataChange(event, 'name')}
                placeholder="Wpisz swoje imię"
                required
              />
              <Form.Control.Feedback type="invalid">Wpisz swoje imię</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Twój adres email</Form.Label>
              <Form.Control
                type="email"
                onChange={event => handleDataChange(event, 'email')}
                placeholder="Wpisz swój adres email"
                required
              />
              <Form.Control.Feedback type="invalid">Wpisz swój email</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Twój numer telefonu</Form.Label>
              <Form.Control
                type="tel"
                onChange={event => handleDataChange(event, 'phone')}
                placeholder="Wpisz swój numer telefonu"
                required
                pattern="^[0-9]*$"
              />
              <Form.Control.Feedback type="invalid">Wpisz swój numer telefonu</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Twoja wiadomość</Form.Label>
              <Form.Control as="textarea" onChange={event => handleDataChange(event, 'message')} rows={6} required />
              <Form.Control.Feedback type="invalid">Wpisz swoją wiadomość</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Check>
                <Form.Check.Input type="checkbox" required id="agreement" />
                <Form.Check.Label for="agreement">
                  Wyrażam zgodę na przetwarzanie moich danych osobowych w celu obsługi zapytania przez formularz
                  kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do obsługi zapytania. Wyrażam zgodę na
                  przesyłanie informacji handlowej za pomocą środków komunikacji elektronicznej oraz kontakt
                  telefoniczny. Oświadczam, że zapoznałem/am się z zapisami{' '}
                  <Link to={`/polityka-prywatnosci`}>klauzuli informacyjnej i polityki prywatności</Link> dostępnymi na
                  stronie.
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>

            <Button variant="dark" type="submit">
              Wyślij
            </Button>
          </Form>
        </div>
      )}
      {success && (
        <p>
          Twoja wiadomość została wysłana.
          <br />
          Skontaktujemy się z Tobą tak szybko, jak to będzie możliwe.
        </p>
      )}
      {error && (
        <p>
          Ups, coś poszło nie tak z wysyłką formularza. Możesz spróbować ponownie lub skontakotwać się mailowo lub
          telefonicznie.
        </p>
      )}
    </>
  );
};

export default ContactForm;
