import * as React from 'react';

import {Instagram, Facebook, Envelope, Telephone} from 'react-bootstrap-icons';

const ContactBox = () => {
  return (
    <div className="contact-page__contact-box">
      <p>
        <strong>Atakama Studio</strong>
        <br />
        arch. Karolina König
      </p>
      <p className="mb-3">
        <Envelope /> kontakt@atakama.studio
        <br />
        <Telephone /> 536 171 518
      </p>
    </div>
  );
};

export default ContactBox;
