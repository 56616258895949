import * as React from 'react';
import {Helmet} from 'react-helmet';

import Layout from '@components/layouts/layout';
import Navigation from '@components/nav-footer/navigation';
import Footer from '@components/nav-footer/footer';

import ContactForm from '@components/contact/form';
import ContactBox from '@components/contact/box';

import {Container, Row, Col} from 'react-bootstrap';

const ContactPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Kontakt – Atakama Studio</title>
        <meta property="og:title" content={`Kontakt – Atakama Studio`} />
      </Helmet>

      <Navigation />
      <div className="contact-page">
        <Container className="contact-page__container">
          <Row>
            <Col>
              <h1>Kontakt</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xs={{order: 'last'}} md={{order: 'first'}} className="contact-page__left-box">
              <ContactForm />
            </Col>
            <Col lg={1}></Col>
            <Col lg={5} xs={{order: 'first'}} md={{order: 'last'}} className="contact-page__right-box">
              <ContactBox />
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </Layout>
  );
};

export default ContactPage;
